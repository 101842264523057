import { useEffect } from 'react';
import { startSigninMainWindow } from '@config/oidc-config';

const LogInPage = () => {
  useEffect(() => {
    startSigninMainWindow();
  }, []);
  return null;
};

export default LogInPage;
